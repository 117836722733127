import { createBrowserRouter } from "react-router-dom"
import Homepage from "./Home"
import NotFound from "./NotFound"

export const router = createBrowserRouter([
  {
    path: "/",
    Component: Homepage,
  },
  {
    path: "/*",
    Component: NotFound,
  },
])
