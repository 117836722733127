import React from "react"
import styles from "./CallToActionSection.module.scss"

const CallToActionSection = () => {
  return (
    <div className={styles.callToAction}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          Ready to Transform Your Content Creation?
        </h3>
        <p className={styles.subtitle}>
          Join the revolution and start creating content smarter, not harder.
          Try ContentBud today.
        </p>
        <a href="/signup" className={styles.ctaButton}>
          Get Started
        </a>
      </div>
    </div>
  )
}

export default CallToActionSection
