import React from "react"
import styles from "./Header.module.scss"

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <h1>ContentBud</h1>
        <p>Your AI-powered content creation partner</p>
      </div>
    </header>
  )
}

export default Header
