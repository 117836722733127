import React from "react"
import styles from "./TestimonialsSection.module.scss"

const TestimonialsSection = () => {
  return (
    <div className={styles.testimonials}>
      <div className={styles.container}>
        <h3 className={styles.title}>What Our Users Say</h3>
        <div className={styles.testimonialsGrid}>
          <div className={styles.testimonialItem}>
            <p className={styles.testimonialText}>
              “ContentBud has revolutionized how we create content for our blog.
              The AI-generated posts are not only high-quality but also
              incredibly relevant to our audience. It's a game-changer for us.”
            </p>
            <p className={styles.userName}>- Alex Smith, Blogger</p>
          </div>
          <div className={styles.testimonialItem}>
            <p className={styles.testimonialText}>
              “I was skeptical at first, but after trying ContentBud, I'm a
              believer. The ease of use and the quality of content it produces
              is beyond my expectations. Highly recommend it!”
            </p>
            <p className={styles.userName}>- Jamie Doe, Digital Marketer</p>
          </div>
          <div className={styles.testimonialItem}>
            <p className={styles.testimonialText}>
              “As a small business owner, ContentBud has been a lifesaver. It
              helps me keep my blog updated with fresh content, which has
              significantly increased my website's traffic.”
            </p>
            <p className={styles.userName}>- Casey Jordan, Entrepreneur</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsSection
