import React from "react"
import styles from "./FeaturesSection.module.scss"

const FeaturesSection = () => {
  return (
    <div className={styles.features}>
      <div className={styles.container}>
        <h3 className={styles.title}>Powerful Features</h3>
        <div className={styles.featuresGrid}>
          <div className={styles.featureItem}>
            <h4>AI-Powered Writing</h4>
            <p>
              Automatically generate high-quality, original content tailored to
              your audience.
            </p>
          </div>
          <div className={styles.featureItem}>
            <h4>SEO-Optimized</h4>
            <p>
              Enhance your search engine rankings with content optimized for SEO
              from the start.
            </p>
          </div>
          <div className={styles.featureItem}>
            <h4>Customizable Templates</h4>
            <p>
              Start with professional templates that you can customize to match
              your brand.
            </p>
          </div>
          <div className={styles.featureItem}>
            <h4>Easy WordPress Integration</h4>
            <p>
              Seamlessly publish your generated content directly to your
              WordPress site.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturesSection
