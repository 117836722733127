import React from "react"
import styles from "./Hero.module.scss"

const HeroSection = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.container}>
        <h2>Generate Stunning Blog Posts in Minutes</h2>
        <p>
          Empower your content creation with AI technology. Explore how
          ContentBud can transform your workflow and boost your site's
          engagement.
        </p>
        <button className={styles.ctaButton}>Get Started</button>
      </div>
    </div>
  )
}

export default HeroSection
