import Header from "../components/Header"
import Hero from "../components/Hero"
import FeaturesSection from "../components/FeaturesSection"
import HowItWorksSection from "../components/HowItWorksSection"
import TestimonialsSection from "../components/TestimonialsSection"
import CallToActionSection from "../components/CallToActionSection"
import Footer from "../components/Footer"

const HomePage = () => {
  return (
    <div className="homePage">
      <Header />
      <Hero />
      <main>
        <FeaturesSection />
        <HowItWorksSection />
        <TestimonialsSection />
        <CallToActionSection />
      </main>
      <Footer />
    </div>
  )
}

export default HomePage
