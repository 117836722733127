import { Link } from "react-router-dom" // Assuming you're using React Router for navigation
import styles from "./NotFound.module.scss"

const NotFound = () => {
  return (
    <div className={styles.notFound}>
      <div className={styles.container}>
        <h1>404 - Page Not Found</h1>
        <p>
          Oops! The page you are looking for doesn't exist or has been moved.
        </p>
        <Link to="/" className={styles.homeLink}>
          Go Back Home
        </Link>
      </div>
    </div>
  )
}

export default NotFound
