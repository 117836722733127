import React from "react"
import styles from "./Footer.module.scss"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p>© {new Date().getFullYear()} ContentBud. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
