import React from "react"
import styles from "./HowItWorksSection.module.scss"

const HowItWorksSection = () => {
  return (
    <div className={styles.howItWorks}>
      <div className={styles.container}>
        <h3 className={styles.title}>How ContentBud Works</h3>
        <div className={styles.stepsContainer}>
          <div className={styles.step}>
            <span className={styles.stepNumber}>1</span>
            <h4>Fill Out the Form</h4>
            <p>
              Start by filling out a simple form about your post's purpose and
              the subject matter you want to cover.
            </p>
          </div>
          <div className={styles.step}>
            <span className={styles.stepNumber}>2</span>
            <h4>AI Understands Your Needs</h4>
            <p>
              ContentBud's AI analyzes your input to understand the intent and
              the key points that your post should address.
            </p>
          </div>
          <div className={styles.step}>
            <span className={styles.stepNumber}>3</span>
            <h4>Receive Your Post</h4>
            <p>
              Within moments, ContentBud generates a well-crafted,
              ready-to-publish post tailored to your requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorksSection
